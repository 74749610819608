body {
  font-family: 'Oswald', sans-serif !important;
}
/* .app {
  min-height: 100vh;
  background-image: url('./assets/background.png');
  background-size: 100% 100%;
  background-position: center top;

} */




.top-bg {
  background-image: url('./assets/top-bg.png');
  background-size: 100% 100%;
}
.top-bg-container {
  background-image: url('./assets/top-bg-container.png');
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}
.top {
  height: 25vh;
}
.title {
  font-size: 3rem;
  color: #656667;
  font-weight: 300;
}
.title-first {
  border-bottom: 2px solid #656667;
}
.content {
  min-height: 60vh;
  background-color: #ff7e2b;
}
.video-player-mock {
  cursor: pointer;
}
.video-player-empty {
  border: 2px solid #000;
}
.breadcrumb {
  background-color: transparent !important;
}
.breadcrumb a, .breadcrumb a:hover {
  color: #656667;
}
.icon-locked {
  bottom: 8%;
  right: 5%;
  width: 0.9rem;
  color: #656667;
}
.year-videos .selected img {
    border: 4px solid #7b3100;
    margin-top: -4px;
    margin-bottom: -4px;
    box-sizing: border-box;
}
.version {
  bottom: 1px;
  right: 2px;
  color: #bb4900;
  font-size: 55%;
}
